import React, { useEffect } from 'react';

const rosa = () => {
  useEffect(() => {
    window.location.href = 'https://rosa.zone';
  }, []);

  return <div>Redirect.....</div>;
}

export default rosa;

// OLD

// import { graphql } from 'gatsby'
// import React, { FC } from 'react'
// import { useTranslation } from 'react-i18next'
// import Layout from 'src/components/layout'
// import CaseStudyTechnicals from 'src/components/sections/caseStudies/CaseStudyTechnicals'
// import BasicTeaser from 'src/components/sections/teaser/basic-teaser'
// import CaseStudiesTeaser from 'src/components/sections/teaser/CaseStudiesTeaser'
// import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'
// import SingleTestimonial from 'src/components/ui/SingleTestimonial'
// import Subtitle from 'src/components/ui/Subtitle'
// import Tile from 'src/components/ui/Tile'
// import H2 from 'src/components/ui/title/H2'
// import { StyledIframe } from 'src/components/sections/webinar'
// import ContactSection from 'src/components/sections/ContactSection'
// import StoreButtons from 'src/components/ui/StoreButtons'
//
// interface IrosaProps {
//   data: {
//     directus: {
//       pages: Array<{ translations: Array<{ content: string }> }>
//     }
//   }
// }
//
// const justifyButtonsCenter = {
//   marginTop: -40,
//   display: "flex",
//   justifyContent: "center",
//   width: "100%",
// }
//
// const rosa: FC<IrosaProps> = ({ data }) => {
//   const translation = data.directus.pages[0].translations[0]
//   if (!translation) {
//     return <h1>Missing translation</h1>
//   }
//
//   const cmsJson = JSON.parse(translation.content)
//
//   const FirstTestimonial = ({ className }: { className: string }) => (
//     <SingleTestimonial
//       className={className}
//       image={cmsJson.sixthSection.firstTestimonial.image}
//       quote={cmsJson.sixthSection.firstTestimonial.quote}
//       caption={cmsJson.sixthSection.firstTestimonial.caption}
//     />
//   )
//
//   const SecondTestimonial = ({ className }: { className: string }) => (
//     <SingleTestimonial
//       className={className}
//       image={cmsJson.sixthSection.secondTestimonial.image}
//       quote={cmsJson.sixthSection.secondTestimonial.quote}
//       caption={cmsJson.sixthSection.secondTestimonial.caption}
//     />
//   )
//
//   const { t } = useTranslation('main-page')
//   return (
//     <Layout title={cmsJson.title} metas={cmsJson.metas}>
//       <CaseStudiesTeaser
//         className="mt-16 md:mt-28"
//         translationContent={{
//           subtitle: cmsJson.firstSection.subtitle,
//           firstPartOfTitle: cmsJson.firstSection.firstPartOfTitle,
//           secondPartOfTitle: cmsJson.firstSection.secondPartOfTitle,
//           sectionName: cmsJson.firstSection.sectionName,
//           image: {
//             url: cmsJson.firstSection.image.url,
//             alt: `title`,
//           },
//         }}
//         hideImageOnMobile
//       >
//         <FormattedHtmlContent
//           dangerouslySetInnerHTML={{ __html: cmsJson.firstSection.excerpt }}
//           className="font-palanquin font-semibold mt-10"
//         />
//         <CaseStudyTechnicals
//           items={[
//             {
//               id: 'platform',
//               header: t('platform'),
//               content: cmsJson.firstSection.platforms,
//               wrapContent: false,
//             },
//           ]}
//           className="mt-8"
//         />
//         <StoreButtons></StoreButtons>
//       </CaseStudiesTeaser>
//       <CaseStudiesTeaser
//         key={cmsJson.secondSection.sectionName}
//         translationContent={{
//           firstPartOfTitle: cmsJson.secondSection.firstPartOfTitle,
//           secondPartOfTitle: cmsJson.secondSection.secondPartOfTitle,
//           subtitle: cmsJson.secondSection.subtitle,
//           sectionName: cmsJson.secondSection.subtitle,
//           image: {
//             url: cmsJson.secondSection.image.url,
//             alt: `${cmsJson.secondSection.firstPartOfTitle} ${cmsJson.secondSection.secondPartOfTitle}`,
//           },
//         }}
//         isReversed
//         isImageSnapped={false}
//       >
//         <FormattedHtmlContent
//           className="font-semibold mt-12 md:ml-10"
//           dangerouslySetInnerHTML={{ __html: cmsJson.secondSection.excerpt }}
//         />
//       </CaseStudiesTeaser>
//       <CaseStudiesTeaser
//         key={cmsJson.thirdSection.sectionName}
//         translationContent={{
//           firstPartOfTitle: cmsJson.thirdSection.firstPartOfTitle,
//           secondPartOfTitle: cmsJson.thirdSection.secondPartOfTitle,
//           subtitle: cmsJson.thirdSection.subtitle,
//           sectionName: cmsJson.thirdSection.subtitle,
//           image: {
//             url: cmsJson.thirdSection.image.url,
//             alt: `${cmsJson.thirdSection.firstPartOfTitle} ${cmsJson.thirdSection.secondPartOfTitle}`,
//           },
//         }}
//         isImageSnapped={false}
//       >
//         <FormattedHtmlContent
//           className="font-semibold mt-12 md:mr-10"
//           dangerouslySetInnerHTML={{ __html: cmsJson.thirdSection.excerpt }}
//         />
//       </CaseStudiesTeaser>
//       <section
//         data-name={cmsJson.fourthSection.sectionName}
//         className="py-10 md:py-20"
//       >
//         <BasicTeaser
//           customTag="div"
//           translationContent={{
//             firstPartOfTitle: cmsJson.fourthSection.firstPartOfTitle,
//             secondPartOfTitle: cmsJson.fourthSection.secondPartOfTitle,
//             subtitle: cmsJson.fourthSection.subtitle,
//             image: cmsJson.fourthSection.image,
//           }}
//           isImageSnapped={false}
//           isReversed
//         >
//           <Tile
//             className="mt-10"
//             title={cmsJson.fourthSection.firstTileTitle}
//             subtitle={cmsJson.fourthSection.firstTileSubtitle}
//           />
//           <Tile
//             className="mt-8"
//             title={cmsJson.fourthSection.secondTileTitle}
//             subtitle={cmsJson.fourthSection.secondTileSubtitle}
//           />
//           <Tile
//             className="mt-8"
//             title={cmsJson.fourthSection.thirdTileTitle}
//             subtitle={cmsJson.fourthSection.thirdTileSubtitle}
//           />
//         </BasicTeaser>
//         <div className="container flex flex-wrap justify-between">
//           <div className="md:w-1/2 z-10">
//             <Tile
//               className="mt-10"
//               title={cmsJson.fourthSection.fourthTileTitle}
//               subtitle={cmsJson.fourthSection.fourthTileSubtitle}
//             />
//             <Tile
//               className="mt-8"
//               title={cmsJson.fourthSection.fifthTileTitle}
//               subtitle={cmsJson.fourthSection.fifthTileSubtitle}
//             />
//             <Tile
//               className="mt-8"
//               title={cmsJson.fourthSection.sixthTileTitle}
//               subtitle={cmsJson.fourthSection.sixthTileSubtitle}
//             />
//             <Tile
//               className="mt-8"
//               title={cmsJson.fourthSection.seventhTileTitle}
//               subtitle={cmsJson.fourthSection.seventhTileSubtitle}
//             />
//           </div>
//           <div className="md:w-1/2 mt-12 md:mt-0">
//             <img
//               src={cmsJson.fourthSection.image2.url}
//               alt={cmsJson.fourthSection.image2.alt}
//             />
//           </div>
//         </div>
//       </section>
//       <CaseStudiesTeaser
//         key={cmsJson.fifthSection.sectionName}
//         translationContent={{
//           firstPartOfTitle: cmsJson.fifthSection.firstPartOfTitle,
//           secondPartOfTitle: cmsJson.fifthSection.secondPartOfTitle,
//           subtitle: cmsJson.fifthSection.subtitle,
//           sectionName: cmsJson.fifthSection.subtitle,
//           image: {
//             url: cmsJson.fifthSection.image.url,
//             alt: `${cmsJson.fifthSection.firstPartOfTitle} ${cmsJson.fifthSection.secondPartOfTitle}`,
//           },
//         }}
//         isReversed
//         isImageSnapped={false}
//       >
//         <FormattedHtmlContent
//           className="font-semibold mt-12 md:ml-10"
//           dangerouslySetInnerHTML={{ __html: cmsJson.fifthSection.excerpt }}
//         />
//       </CaseStudiesTeaser>
//       <section data-name="Testimonials" className="container py-10 md:py-20">
//         <div className="flex flex-wrap">
//           <div className="md:w-1/2">
//             <Subtitle>{cmsJson.sixthSection.subtitle}</Subtitle>
//             <H2
//               firstPart={cmsJson.sixthSection.firstPartOfTitle}
//               secondPart={cmsJson.sixthSection.secondPartOfTitle}
//               className="mb-12 md:mb-36"
//             />
//             <FirstTestimonial className="hidden md:block" />
//             <SecondTestimonial className="md:hidden" />
//           </div>
//           <div className="md:w-1/2">
//             <SecondTestimonial className="hidden md:block mt-16" />
//             <FirstTestimonial className="md:hidden mt-16" />
//           </div>
//         </div>
//       </section>
//       <section
//         id="webinar"
//         className="container py-10 md:py-20"
//         data-name={cmsJson.seventhSection.sectionName}
//       >
//         <Subtitle className="mb-2 md:mb-6 center">
//           {cmsJson.seventhSection.title}
//         </Subtitle>
//
//         <div className="mt-16 mb-12 text-center">
//           <StyledIframe
//             className="mx-auto max-w-full"
//             src={`https://www.youtube.com/embed/${cmsJson.seventhSection.videoEmbedId}`}
//             title="Video Embed"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           />
//         </div>
//         <div style={justifyButtonsCenter}>
//           <StoreButtons></StoreButtons>
//         </div>
//       </section>
//       <ContactSection />
//     </Layout>
//   )
// }
//
// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//     directus {
//       pages(filter: { slug: { _eq: "/rosa" } }) {
//         translations(filter: { languages_code: { code: { _eq: $language } } }) {
//           content
//         }
//       }
//     }
//   }
// `
//
// rosa.defaultProps = {}
//
// export default rosa
